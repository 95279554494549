import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { EventsApiService } from '../services/api/events-api.service';
import { Meta, Title } from '@angular/platform-browser';
import { catchError, of, tap } from 'rxjs';
import { GtmService } from '../services/gtm.service';
import { NavigationService } from '../services/navigation.service';
import { LoadingService } from '../services/loading.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { DOCUMENT } from '@angular/common';

export const eventDetailWithIdResolver: ResolveFn<boolean> = (route, state) => {
  const eventsApiService = inject(EventsApiService);
  const metaService = inject(Meta);
  const title = inject(Title);
  const gtmService = inject(GtmService);
  const loadingService = inject(LoadingService);
  const navService = inject(NavigationService);
  loadingService.showLoading();
  return eventsApiService.getEventDetailWithId(route.params['eventId']).pipe(
    tap((detail: any) => {
      gtmService.push({
        event: 'eventDetailTicketList',
        name: detail.name,
        date: {
          start: detail.startDate,
          end: detail.endDate,
        },
        lowestPrice: detail.lowestPricedProduct,
        slug: detail.slug,
        artists: detail.artists,
        categories: detail.categories,
        place: {
          name: detail.placeDetail.name,
          address: detail.placeDetail.address,
        },
      });
      gtmService.fbq('track', 'ViewContent', {
        content_ids: [detail.id],
        content_type: 'product',
      });
      title.setTitle(detail.seoTitle || detail.name);
      metaService.addTags([
        {
          property: 'og:title',
          content: detail.seoTitle || detail.name,
          itemprop: 'title',
        },
        {
          name: 'description',
          content: detail.seoDescription || detail.description,
        },
        {
          property: 'og:description',
          content: detail.seoDescription || detail.description,
          itemprop: 'description',
        },
        {
          property: 'og:image',
          content: 'favicon.ico',
          itemprop: 'image',
        },
        {
          name: 'keywords',
          content: detail.seoTags || 'biletzero',
        },
      ]);
      loadingService.hideLoading();
    }),
    catchError((err) => {
      loadingService.hideLoading();
      navService.back();
      return of(null);
    })
  );
};

import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
  inject,
  PLATFORM_ID,
  LOCALE_ID,
} from '@angular/core';
import {
  DOCUMENT,
  isPlatformBrowser,
  Location,
  registerLocaleData,
} from '@angular/common';
import {
  PreloadAllModules,
  provideRouter,
  withInMemoryScrolling,
  withPreloading,
} from '@angular/router';
import { routes } from './app.routes';
import {
  HttpClient,
  provideHttpClient,
  withInterceptors,
  withJsonpSupport,
} from '@angular/common/http';
import { mockApiInterceptor } from '../interceptors/mock-api.interceptor';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { LocalizeRouterHttpLoader } from '@gilsdav/ngx-translate-router-http-loader';
import {
  CacheMechanism,
  LocalizeParser,
  LocalizeRouterService,
  LocalizeRouterSettings,
  withLocalizeRouter,
} from '@gilsdav/ngx-translate-router';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import localeEn from '@angular/common/locales/en';
import localeTr from '@angular/common/locales/tr';
import { firstValueFrom, tap } from 'rxjs';
import { cityInterceptor } from '../interceptors/city.interceptor';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { authInterceptor } from '../interceptors/auth.interceptor';
import { provideToastr } from 'ngx-toastr';
import { salesChannelInterceptor } from '../interceptors/sales-channel.interceptor';
import { cardApiInterceptor } from '../interceptors/card-api.interceptor';

// registerLocaleData(localeEn, 'en');
registerLocaleData(localeTr, 'tr');

export function HttpLoaderFactory(
  translate: TranslateService,
  location: Location,
  settings: LocalizeRouterSettings,
  http: HttpClient
) {
  return new LocalizeRouterHttpLoader(
    translate,
    location,
    { ...settings, alwaysSetPrefix: true },
    http,
    `/assets/locales/locales.json`
  );
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, `/assets/locales/`, '.json');
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withPreloading(PreloadAllModules),
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
      withLocalizeRouter(routes, {
        parser: {
          provide: LocalizeParser,
          useFactory: HttpLoaderFactory,
          deps: [
            TranslateService,
            Location,
            LocalizeRouterSettings,
            HttpClient,
          ],
        },
        cacheMechanism: CacheMechanism.Cookie,
        cookieFormat: '{{value}};{{expires:20}};path=/',
      })
    ),
    provideEnvironmentNgxMask(),
    provideAnimationsAsync(),
    provideHttpClient(
      withInterceptors([
        mockApiInterceptor,
        cardApiInterceptor,
        cityInterceptor,
        authInterceptor,
        salesChannelInterceptor,
      ]),
      withJsonpSupport()
    ),
    importProvidersFrom([
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
      }),
    ]),
    {
      provide: APP_INITIALIZER,
      useFactory: () => {
        const translate = inject(TranslateService);
        translate.addLangs(['tr'] as Array<string>);
        translate.setDefaultLang('tr');
        const platformId = inject(PLATFORM_ID);
        const localizeRouterService = inject(LocalizeRouterService);
        const doc = inject(DOCUMENT);
        return () =>
          firstValueFrom(
            localizeRouterService.hooks.initialized.pipe(
              tap(() => {
                if (isPlatformBrowser(platformId)) {
                  const html = doc.body.parentElement;
                  html?.setAttribute(
                    'lang',
                    translate.currentLang || translate.defaultLang || 'tr'
                  );
                }
              })
            )
          );
      },
      multi: true,
    },
    {
      provide: LOCALE_ID,
      deps: [TranslateService],
      useFactory: () => {
        // const translateService = inject(TranslateService);
        return 'tr'; //translateService.currentLang;
      },
    },
    provideToastr({ positionClass: 'toast-bottom-center', enableHtml: true }),
  ],
};

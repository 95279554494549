import { inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private history: string[] = [];
  protected localizeRouterService = inject(LocalizeRouterService);
  protected currentUrl = '';

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.urlAfterRedirects;
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  back() {
    this.history.pop(); // Remove the current url
    if (this.history.length > 0) {
      const prevUrl = this.history[this.history.length - 1];
      if (
        (this.currentUrl.includes(prevUrl) ||
          prevUrl.includes(this.currentUrl)) &&
        prevUrl !== this.localizeRouterService.translateRoute('/')
      ) {
        this.history.pop();
      }
      this.router.navigateByUrl(this.history[this.history.length - 1], {
        replaceUrl: true,
      });
    } else {
      this.router.navigate(
        this.localizeRouterService.translateRoute(['/']) as string[],
        { replaceUrl: true }
      ); // Fallback to the home page or a default route
    }
  }
}

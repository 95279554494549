import { effect, Injectable, signal } from '@angular/core';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  shareReplay,
  Subscription,
  take,
  tap,
  timer,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  requests = new Map();
  $showLoading = new BehaviorSubject(false);
  subs: Subscription;
  showLoading$ = this.$showLoading.asObservable().pipe(
    distinctUntilChanged(),
    tap((show) => {
      if (show) {
        if (this.subs) {
          this.subs.unsubscribe();
        }
        this.subs = timer(5000)
          .pipe(take(1))
          .subscribe(() => {
            this.hideLoading();
          });
      }
    }),
    shareReplay(1)
  );
  constructor() {}
  showLoading() {
    this.$showLoading.next(true);
  }
  hideLoading() {
    this.$showLoading.next(false);
  }
}

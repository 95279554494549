export const profileMenu = [
  {
    icon: 'receipt',
    link: 'orders',
    label: 'orders',
  },
  {
    icon: 'communication',
    link: 'userCom',
    label: 'userCom',
  },
  {
    icon: 'bookmark',
    link: 'saves',
    label: 'saves',
  },
  /*   {
    icon: 'share2',
    link: 'linkedAcounts',
    label: 'linkedAcounts',
  }, */
  /* {
    icon: 'shield',
    link: '/privacy',
    label: 'privacy',
  },
  {
    icon: 'shield-check',
    link: '/terms',
    label: 'terms',
  },
  {
    icon: 'shield-user',
    link: '/kvkk',
    label: 'kvkk',
  }, */
  /*   {
    icon: 'network',
    link: 'changeLang',
    label: 'changeLang',
  }, */
  {
    icon: 'lock',
    link: 'changePassword',
    label: 'changePassword',
  },
];

import { ActivatedRoute, CanActivateFn, Router } from '@angular/router';
import { REF, AFF, FACEBOOK, GOOGLE } from '../constants/storage.keys';
import { environment } from '../environments/environment';
import { inject } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../services/auth.service';

export const channelGuard: CanActivateFn = (route, state) => {
  const cookieService = inject(CookieService);
  const authService = inject(AuthService);
  const router = inject(Router);
  const activatedRoute = inject(ActivatedRoute);
  const queryParams = route.queryParams;
  let isReload = false;
  const date = new Date();
  date.setMilliseconds(date.getMilliseconds() + environment.refAffStorageTime);
  if (REF in queryParams) {
    cookieService.set(REF, queryParams[REF], {
      expires: date,
      path: '/',
    });
    isReload = true;
  }
  if (AFF in queryParams) {
    cookieService.set(AFF, queryParams[AFF], {
      expires: date,
      path: '/',
    });
    isReload = true;
  }
  if (FACEBOOK in queryParams) {
    sessionStorage.setItem(FACEBOOK, queryParams[FACEBOOK]);
  }
  if (GOOGLE in queryParams) {
    sessionStorage.setItem(GOOGLE, queryParams[GOOGLE]);
  }
  if ('auth_token' in queryParams) {
    isReload = true;
    authService.accessToken = queryParams['auth_token'];
  }
  if (isReload) {
    router.navigate([location.pathname], {
      relativeTo: activatedRoute,
      queryParams: {
        [REF]: null,
        [AFF]: null,
        auth_token: null,
      },
      replaceUrl: true,
      queryParamsHandling: 'merge',
      onSameUrlNavigation: 'reload',
    });
  }
  return true;
};

import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { map, Observable, take } from 'rxjs';
import { Event } from '../../interfaces/event.interface';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EventsApiService {
  protected http = inject(HttpClient);

  public getEventDetail(eventSlug: string): Observable<Event> {
    return this.http.get<any>(`${environment.apiHost}Event/slug/${eventSlug}`);
  }
  public getEventDetailWithId(eventId: string): Observable<Event> {
    return this.http.get<any>(`${environment.apiHost}Event/${eventId}`);
  }
}

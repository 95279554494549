import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GtmService {
  protected document = inject(DOCUMENT);
  protected win: Window & { dataLayer?: Array<any>; fbq?: (...props) => void } =
    this.document.defaultView;

  constructor() {}

  push(data) {
    if (!this.win.dataLayer) {
      this.win.dataLayer = [];
    }
    this.win.dataLayer?.push(data);
  }

  fbq(type: string, name: string, data: any) {
    if (!this.win.fbq) {
      return;
    }
    console.log('fbq');
    this.win.fbq(type, name, data);
  }
}

import { inject } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { catchError, map, of, tap } from 'rxjs';
import { CheckoutApiService } from '../services/api/checkout-api.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { NavigationService } from '../services/navigation.service';
import { LoadingService } from '../services/loading.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

export const orderDetailResolver: ResolveFn<boolean> = (route, state) => {
  const checkoutApiService = inject(CheckoutApiService);
  const localize = inject(LocalizeRouterService);
  const router = inject(Router);
  const loadingService = inject(LoadingService);
  const navService = inject(NavigationService);
  const toastr = inject(ToastrService);
  const translate = inject(TranslateService);
  loadingService.showLoading();

  return checkoutApiService
    .checkoutOnSuccess({
      orderId: route.params['orderId'],
      ...route.queryParams,
    })
    .pipe(
      map((detail: any) => {
        return detail.order;
      }),
      catchError((err) => {
        if (err.error.errors) {
          toastr.error(Object.values(err.error.errors).join(' '));
        } else if (err.error.error) {
          toastr.error(err.error.error.messages.join(' '));
        } else {
          toastr.error(translate.instant('errorMsg.generic'));
        }
        return of(null);
      }),
      tap((data) => {
        loadingService.hideLoading();
        if (!data) {
          const path = localize.translateRoute(['/']) as string[];
          router.navigate(path);
        }
      }),
      catchError((err) => {
        loadingService.hideLoading();
        navService.back();
        return of(null);
      })
    );
};

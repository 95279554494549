import { inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CheckoutApiService {
  protected http = inject(HttpClient);
  constructor() {}

  applyCouponCode(data: { code: string; cardId: string }) {
    return this.http
      .post<any>(`${environment.apiHost}CouponCode/apply`, data)
      .pipe(map((response: any) => response.data));
  }
  removeCouponCode(data: { cardId: string }) {
    return this.http
      .post<any>(`${environment.apiHost}CouponCode/remove`, data)
      .pipe(map((response: any) => response.data));
  }

  checkout(data: any) {
    return this.http.post<any>(`${environment.apiHost}Checkout`, data);
  }

  getInstallments(amount, binCode) {
    return this.http.get<any>(
      `${environment.apiHost}Checkout/Installment?amount=${amount}&binCode=${binCode}`
    );
  }

  checkoutOnError(data) {
    const searchParams = new URLSearchParams(data);
    return this.http.get<any>(
      `${environment.apiHost}Checkout/order/error?${searchParams.toString()}`
    );
  }
  checkoutOnSuccess(data) {
    const searchParams = new URLSearchParams(data);
    return this.http.get<any>(
      `${environment.apiHost}Checkout/order/${
        data.orderId
      }/success?${searchParams.toString()}`
    );
  }
}

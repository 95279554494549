@if ({autoCompleteResults: autoCompleteResults$ | async, latestSearches:
latestSearchs$ | async}; as data){
<div
  class="fixed inset-0 bg-black/40 z-50"
  [class.hidden]="
    !showOptions() ||
    (!data.autoCompleteResults?.options?.length && !data.latestSearches.length)
  "
  [class.block]="
    showOptions() &&
    (data.autoCompleteResults?.options?.length || data.latestSearches.length)
  "
  (click)="showOptions.set(false)"
></div>
<div class="auto-suggest relative w-full text-black z-51">
  <div
    class="absolute z-2 inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none"
  >
    <span class="iconbz-search text-lg text-neutral-600"></span>
  </div>
  <input
    type="text"
    [(ngModel)]="searchText"
    (keydown)="onKeyDown($event)"
    (click)="onInputFocus()"
    class="relative z-1 bg-white border border-neutral-600 text-neutral-600 text-base rounded-[1.75rem] block w-full ps-10 p-3 placeholder-neutral-600 focus:ring-purple-800 focus:border-purple-800 focus-visible:outline-purple-800"
  />
  @if(showOptions() && (data.autoCompleteResults?.options?.length ||
  data.latestSearches.length)){
  <div
    class="absolute top-full left-0 flex flex-col min-w-full bg-white dark:bg-slate-700 px-4 pt-10 -mt-6 rounded-b-3xl border border-black"
  >
    <!-- Latest Search Results -->
    <div class="max-h-80 overflow-auto">
      @if(data.latestSearches?.length &&
      !data.autoCompleteResults?.options?.length) {
      <div class="flex justify-between items-center text-neutral-500">
        <div class="font-syne text-sm font-bold text-black">
          {{ "autoComplete.latestSearchTitle" | translate }}
        </div>
        @if (data.latestSearches?.length>1){
        <button
          class="flex flex-row gap-1 items-center"
          (click)="clearAllLatestSearchResults()"
        >
          <span class="liga-trash ml-auto"></span>
          {{ "clear" | translate }}</button
        >}
      </div>
      <div class="flex flex-col mt-1">
        @for (item of data.latestSearches; track item; let i = $index){
        @if(item){
        <div
          class="flex items-center text-sm gap-2 py-2 cursor-pointer text-neutral-500"
          (click)="fillSearch($event, item)"
        >
          <span class="iconbz-search"></span>
          <span>{{ item }}</span>
          @if(i){
          <button
            class="liga-cross ml-auto"
            (click)="deleteLatestSearch($event, item)"
          ></button
          >}
        </div>
        } }
      </div>
      }
      <!-- Autocomplete Results -->
      @if (data.autoCompleteResults; as result) {
      <!-- For loop of search result -->
      @for (item of result.options; track item.id) {
      <a
        class="flex gap-2 border-b border-zinc-100 py-2"
        (click)="closeOptions()"
        [routerLink]="
          (item.entityType !== 'EVENT'
            ? [
                '/',
                dataTypeMap[item.entityType] || 'events',
                item.slug || item.name | slugify
              ]
            : [
                '/',
                dataTypeMap[item.entityType] || 'events',
                item.city || activeCity() | slugify,
                item.categorySlug || (item.categoryName || 'konser' | slugify),
                item.slug || item.name | slugify
              ]
          ) | localize
        "
      >
        <image
          class="min-w-10 w-10 h-10 min-h-10 aspect-square object-cover rounded-full overflow-hidden my-auto"
          [src]="item.image"
          [alt]="item.name"
        ></image>
        <div class="flex flex-col justify-center">
          <div class="text-sm font-bold">{{ item.name }}</div>
          @if (item.entityType !== 'ARTIST') {
          <div class="block">
            @if (item.entityType === 'EVENT') {
            <div class="inline text-xs mr-0.5">{{ item.place }},</div>
            }
            <div class="inline text-xs">{{ item.city }}</div>
            @if (item.entityType === 'EVENT') {
            <div class="inline text-xs ml-0.5">
              - {{ item.startDate | date : dateFormat }}
            </div>
            }
          </div>
          }
        </div>
        <div class="my-auto ml-auto uppercase text-stone-400 text-xs">
          @if(item.entityType === 'EVENT') {
          {{
            item.categoryName ||
              ("autoComplete.entity." + item.entityType | translate)
          }}
          } @else {
          {{ "autoComplete.entity." + item.entityType | translate }}
          }
        </div>
      </a>
      } }
    </div>
    <button
      (click)="seeAll()"
      class="mx-auto text-sm text-purple-800 underline capitalize py-2"
    >
      {{ "autoComplete.search" | translate }}
    </button>
  </div>
  }
</div>
}

export const inValidTicketNumberRules = (basket) => {
  const entries = basket?.cardEntryDtos || [];
  const groupedEntries = entries.reduce((acc, curr) => {
    if (!acc[curr.productPriceId]) {
      acc[curr.productPriceId] = {
        ...curr,
        totalTicketNumber: curr.quantity,
        requiredTicketCount:
          (curr.ticketNumber - (curr.quantity % curr.ticketNumber)) %
          curr.ticketNumber,
      };
    } else {
      const totalTicketNumber = acc[curr.productPriceId].totalTicketNumber + 1;
      acc[curr.productPriceId].totalTicketNumber = totalTicketNumber;
      acc[curr.productPriceId].requiredTicketCount =
        (curr.ticketNumber - (totalTicketNumber % curr.ticketNumber)) %
        curr.ticketNumber;
    }
    return acc;
  }, {});
  return Object.values(groupedEntries).filter(
    (entry: any) => entry.requiredTicketCount
  );
};

import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { filter, map, of, switchMap } from 'rxjs';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

export const isUserPhoneVerifiedGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const userService = inject(UserService);
  const router = inject(Router);
  const localizeRouterService = inject(LocalizeRouterService);
  return authService.accessToken$.pipe(
    switchMap((token) => {
      return token
        ? userService.user$.pipe(
            filter(Boolean),
            map((user) => {
              return user.phoneNumberConfirmed;
            })
          )
        : of(true);
    }),
    map((isVerified) => {
      if (!isVerified) {
        return router.createUrlTree(
          localizeRouterService.translateRoute(['/register']) as Array<string>
        );
      }
      return isVerified;
    })
  );
};

import { AfterViewInit, Component, EventEmitter, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { LocalizeRouterPipe } from '@gilsdav/ngx-translate-router';
import { TranslateModule } from '@ngx-translate/core';
import { take, timer } from 'rxjs';

@Component({
  selector: 'cookie-message',
  standalone: true,
  imports: [RouterLink, TranslateModule, LocalizeRouterPipe],
  templateUrl: './cookie-message.component.html',
  styleUrl: './cookie-message.component.scss',
})
export class CookieMessageComponent implements AfterViewInit {
  @Output() onClose = new EventEmitter<void>();

  ngAfterViewInit(): void {
    timer(3000)
      .pipe(take(1))
      .subscribe(() => this.onClose.emit());
  }
}

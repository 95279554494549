import { CanActivateChildFn, CanActivateFn, ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { LayoutService } from '../services/layout.service';

export const layoutGuard: CanActivateFn | CanActivateChildFn = (
  route,
  state
) => {
  const layoutService = inject(LayoutService);
  layoutService.layoutData.set(route.data);
  return true;
};

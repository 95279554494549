import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {
  BehaviorSubject,
  catchError,
  distinctUntilChanged,
  map,
  of,
  skipWhile,
  tap,
} from 'rxjs';
import { UserService } from '../user.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class CardService {
  protected http = inject(HttpClient);
  protected userService = inject(UserService);
  protected cookieService = inject(CookieService);
  protected $card = new BehaviorSubject(null);
  protected card$ = this.$card.asObservable();
  constructor() {}
  getCurrentCard(eventId: string, force = false) {
    let responseExist = false;
    if (!this.$card.getValue() || force) {
      this.http
        .get<any>(`${environment.apiHost}Card/current?eventId=${eventId}`, {
          withCredentials: true,
        })
        .pipe(
          map((response: any) => response.data),
          catchError(() => of(null)),
          tap(() => {
            responseExist = true;
          })
        )
        .subscribe((data) => {
          this.$card.next(data);
        });
    }

    return this.card$.pipe(
      skipWhile(() => (force ? !responseExist : false)),
      distinctUntilChanged((prev, curr) => prev?.id === curr?.id)
    );
  }
}

export const environment = {
  production: false,
  apiHost: 'https://apiv2.biletzero.xyz/api/v1/',
  gmapApiKey: 'AIzaSyCyXmnKkkz8EEnYQDg0jUMzHSlItjHmEGs',
  cdn: 'https://cdncloud.biletzero.com/',
  socketUrl: 'https://cardhub.biletzero.xyz',
  socketPath: '/product-stock',
  domain: 'biletzero.xyz',
  refAffStorageTime: 24 * 60 * 60 * 1000,
  salesChannel: 'bzcom',
  multiCityDisabled: false,
  initialCity: 'Türkiye',
  priceColorPalette: [
    '#ebc1c1',
    '#ebd8c1',
    '#e6ebc1',
    '#cdebc1',
    '#c1ebdd',
    '#c1ebe7',
    '#c1e1eb',
    '#c1d0eb',
    '#c8c1eb',
    '#dec1eb',
    '#ebc1e1',
  ],
  paymentMethods: ['creditOrDebit'],
  tenantId: '7c7479f6-3d0f-4c73-8595-c6d3e5d695ab',
  tenantName: 'Biletzero',
  enablePatrnerShip: true,
};

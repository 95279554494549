<div
  (click)="onClose.emit()"
  class="flex flex-col gap-2 px-4 py-3 shadow-sm shadow-black bg-white text-black fixed bottom-4 left-4 right-4 md:left-auto z-51 md:max-w-lg"
>
  <p class="relative text-sm md:text-base pr-4">
    {{ "cookieMsg" | translate }}
    <span class="absolute top-0 right-0 liga-cross"></span>
  </p>
  <p class="text-xs md:text-sm text-gray-400">
    {{ "cookieDetailMsg" | translate }}
    <a
      class="text-purple-400 underline underline-offset-1"
      [routerLink]="['/', 'legalPages', 'cookiepolicy'] | localize"
      >{{ "cookiepolicy" | translate }}</a
    >
  </p>
</div>

import { Injectable, OnDestroy } from '@angular/core';
import {
  Observable,
  Subject,
  distinctUntilChanged,
  shareReplay,
  takeUntil,
} from 'rxjs';

@Injectable()
export class BreakpointObserver implements OnDestroy {
  protected queryMap: Map<string, Observable<boolean>> = new Map();

  protected mediaMatcherMap: Map<
    string,
    { matcher: MediaQueryList; removeListener: () => void }
  > = new Map();

  protected onDestroy$: Subject<void> = new Subject();

  public isSmallScreen$ = this.observe('screen and (max-width: 769px)');

  constructor() {}

  observe(query: string) {
    if (this.queryMap.has(query)) {
      return this.queryMap.get(query);
    }
    const mediaMatcher = window.matchMedia(query);
    const observer = new Observable<boolean>((observe) => {
      const callback = (event: MediaQueryListEvent) => {
        observe.next(event.matches);
      };
      mediaMatcher.addEventListener('change', callback);
      this.mediaMatcherMap.set(query, {
        matcher: mediaMatcher,
        removeListener: () =>
          mediaMatcher.removeEventListener('change', callback),
      });
      observe.next(mediaMatcher.matches);
    }).pipe(distinctUntilChanged(), takeUntil(this.onDestroy$), shareReplay(1));
    this.queryMap.set(query, observer);

    return this.queryMap.get(query);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.mediaMatcherMap.forEach((matcher) => {
      matcher.removeListener();
    });
  }
}

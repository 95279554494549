import { inject } from '@angular/core';
import { CanActivateFn, CanActivateChildFn, Router } from '@angular/router';
import { switchMap, of, take, map, filter } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { NavigationService } from '../services/navigation.service';
import { UserService } from '../services/user.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

export const onlyGuestGuard: CanActivateFn | CanActivateChildFn = (
  route,
  state
) => {
  const userService = inject(UserService);
  const router = inject(Router);
  const localizeRouter = inject(LocalizeRouterService);
  // Check the authentication status
  return inject(AuthService)
    .check()
    .pipe(
      switchMap((authenticated) => {
        // If the user is not authenticated...
        if (authenticated) {
          return userService.user$.pipe(
            filter(Boolean),
            take(1),
            map((user) => {
              return (
                !user.phoneNumberConfirmed ||
                router.createUrlTree(
                  localizeRouter.translateRoute(['/', 'home']) as Array<string>
                )
              );
            })
          );
        }
        // Allow the access
        return of(true);
      })
    );
};

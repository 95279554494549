import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service';

export const cardApiInterceptor = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  const url = request.url;
  const userService = inject(UserService);
  const authService = inject(AuthService);
  if (url.includes('/Card/current') && !authService.accessToken) {
    const cloned = request.clone({
      setHeaders: {
        guest: userService.guestId,
      },
    });
    return next(cloned);
  }
  return next(request);
};

import { HttpInterceptorFn } from '@angular/common/http';
import { environment } from '../environments/environment';
import { inject } from '@angular/core';
import { LoadingService } from '../services/loading.service';
import { catchError, tap, throwError } from 'rxjs';
import { AFF, FACEBOOK, GOOGLE, REF } from '../constants/storage.keys';
import { CookieService } from 'ngx-cookie-service';

export const salesChannelInterceptor: HttpInterceptorFn = (request, next) => {
  const cookieService = inject(CookieService);
  const loadingService = inject(LoadingService);
  if (request.url.startsWith(environment.apiHost)) {
    const newReq = request.clone({
      setHeaders: {
        'Sales-Channel': environment.salesChannel,
        tenant: environment.tenantId,
        ...(sessionStorage.getItem(FACEBOOK)
          ? { [FACEBOOK]: sessionStorage.getItem(FACEBOOK) }
          : null),
        ...(sessionStorage.getItem(GOOGLE)
          ? { [GOOGLE]: sessionStorage.getItem(GOOGLE) }
          : null),
        ...(cookieService.get(REF) ? { refId: cookieService.get(REF) } : null),
        ...(cookieService.get(AFF)
          ? { affiliateId: cookieService.get(AFF) }
          : null),
      },
    });
    loadingService.showLoading();
    return next(newReq).pipe(
      tap(() => loadingService.hideLoading()),
      catchError((err) => {
        loadingService.hideLoading();
        return throwError(err);
      })
    );
  }
  return next(request);
};

import { Component, effect, inject, Input, model, signal } from '@angular/core';
import { SearchService } from '../../services/api/search.service';
import { FormsModule } from '@angular/forms';
import { AsyncPipe, DatePipe } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ImageComponent } from '../image/image.component';
import { dataTypeMap } from '../../constants/event-types';
import {
  LocalizeRouterPipe,
  LocalizeRouterService,
} from '@gilsdav/ngx-translate-router';
import { SlugifyPipe } from '../../pipes/slugify.pipe';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import {
  BehaviorSubject,
  filter,
  map,
  Observable,
  shareReplay,
  switchMap,
  take,
  timer,
} from 'rxjs';
import { CurrentCityService } from '../../services/current-city.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'auto-suggest, [auto-suggest]',
  standalone: true,
  imports: [
    FormsModule,
    AsyncPipe,
    TranslateModule,
    ImageComponent,
    LocalizeRouterPipe,
    SlugifyPipe,
    RouterLink,
    DatePipe,
  ],
  templateUrl: './auto-suggest.component.html',
  styleUrl: './auto-suggest.component.scss',
})
export class AutoSuggestComponent {
  protected activatedRoute = inject(ActivatedRoute);
  protected router = inject(Router);
  protected searchService = inject(SearchService);
  protected currentCityService = inject(CurrentCityService);
  protected localize = inject(LocalizeRouterService);
  protected toastrService = inject(ToastrService);
  protected translateService = inject(TranslateService);
  protected searchText$ = new BehaviorSubject('');
  protected $searchText = this.searchText$.asObservable();
  public latestSearchs$ = this.searchService.getLatestSearches().pipe(
    switchMap((results) => {
      return this.$searchText.pipe(
        filter((text) => !text || text.length > 2),
        map((text) => {
          const res = [text, ...results];
          return Array.from(new Set(res));
        })
      );
    }),
    shareReplay(1)
  );
  showOptions = signal(false);
  activeCity = this.currentCityService.city;
  public autoCompleteResults$: Observable<any> =
    this.searchService.getAutoCompleteResults();

  public searchText = model('');

  public readOnlySearchText = this.searchText.asReadonly();

  public dataTypeMap = dataTypeMap;

  public dateFormat = 'd MMMM, EEEE';

  @Input() public set currentValue(val: string) {
    this.searchText.set(val);
  }

  constructor() {
    effect(() => {
      const searchValue = this.readOnlySearchText();
      this.searchText$.next(searchValue);
      this.searchService.getAutoCompleteResults(searchValue);
    });
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.code !== 'Enter') {
      return;
    }
    (event.target as HTMLElement).blur();
    this.seeAll();
  }

  onInputFocus() {
    this.showOptions.set(true);
  }

  fillSearch(event: MouseEvent | TouchEvent, searchKey: string) {
    event.preventDefault();
    event.stopImmediatePropagation();
    event.stopPropagation();
    this.searchText.set(searchKey);
  }

  closeOptions() {
    timer(1)
      .pipe(take(1))
      .subscribe(() => {
        this.showOptions.set(false);
      });
  }

  seeAll() {
    this.showOptions.set(false);
    if (
      this.readOnlySearchText()?.length < 3 &&
      this.readOnlySearchText()?.length !== 0
    ) {
      this.toastrService.warning(
        this.translateService.instant('errorMsg.searchValidation')
      );
      return;
    }
    if (this.readOnlySearchText()?.length > 0) {
      this.searchService.addLatestSearches(this.readOnlySearchText());
    }
    this.router.navigate(
      this.localize.translateRoute(['/', 'explore']) as Array<string>,
      {
        queryParams: {
          searchText: this.readOnlySearchText() || null,
        },
      }
    );
  }

  deleteLatestSearch(event: MouseEvent, item) {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    this.searchService.deleteLatestSearch(item);
  }

  clearAllLatestSearchResults() {
    this.searchService.deleteAllLatestSearches();
  }
}

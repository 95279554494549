import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { CardService } from '../services/api/card.service';
import { map, shareReplay, take } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../services/layout.service';
import { inValidTicketNumberRules } from '../helpers/invalid-ticket-number.helper';
import { StringHelper } from '../helpers/string.helper';

export const checkoutCardGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const localize = inject(LocalizeRouterService);
  const cardService = inject(CardService);
  const toastr = inject(ToastrService);
  const translateService = inject(TranslateService);
  const layoutService = inject(LayoutService);
  const eventId = route.params?.['eventId'];
  const mustHideErrorMsgs = route.queryParamMap.get('sobe');
  return cardService.getCurrentCard(eventId, true).pipe(
    map((card) => {
      const entries = card?.cardEntryDtos || [];
      if (!entries.length) {
        if (!mustHideErrorMsgs) {
          toastr.error(
            translateService.instant('checkout.error.cardMustHaveItem')
          );
        }
        return router.createUrlTree(
          localize.translateRoute(['/', 'buyTicket', eventId]) as Array<string>,
          {
            queryParams: {
              hasError: Number(route.queryParams['hasError'] || 0) + 1,
            },
          }
        );
      }
      const targetTimeInMs = StringHelper.convertTimeToMs(card?.timeLeft);
      if (targetTimeInMs <= 0) {
        return router.createUrlTree(
          localize.translateRoute(['/', 'buyTicket', eventId]) as Array<string>,
          {
            queryParams: {
              hasError: Number(route.queryParams['hasError'] || 0) + 1,
            },
          }
        );
      }
      const invalidEntries = inValidTicketNumberRules(card);
      if (invalidEntries.length) {
        const listOfTickets = invalidEntries.map((entry: any) => {
          return translateService.instant(
            'checkout.error.invalidTicketNumber',
            {
              name:
                entry.productName === entry.priceName
                  ? entry.productName
                  : `${entry.productName} (${entry.priceName})`,
              missingAmount: entry.requiredTicketCount,
            }
          );
        });
        toastr.error(listOfTickets.join(''));
        return router.createUrlTree(
          localize.translateRoute(['/', 'buyTicket', eventId]) as Array<string>,
          {
            queryParams: {
              hasError: Number(route.queryParams['hasError'] || 0) + 1,
            },
          }
        );
      }
      layoutService.layoutData.set(route.data);
      return true;
    }),
    take(1),
    shareReplay(1)
  );
};
